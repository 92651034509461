<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="application.pages"
      :validation-schema="createValidationSchema"
    />
  </div>
</template>

<script>
import service from '../api/flowProhibitionService'
import {
  CargillCrudMetaView,
  responseToOptionsInContext,
  helpers
} from '@cargill/shared'
import _ from 'lodash'
import { generateFieldsSchema } from '@brain/core'
import * as yup from 'yup'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      createValidationSchema: Function
    }
  },
  created() {
    const getMeta = async () => {
      const meta = await service.getMeta()
      const ctx = await service.getValidationContext()
      responseToOptionsInContext(ctx)
      helpers.updateOptionsRefs(
        ctx.centerOriginOptions,
        ctx.centersOriginByCenterTypeOrigin
      )
      helpers.updateOptionsRefs(
        ctx.centerDestinationOptions,
        ctx.centersDestinationByCenterTypeDestination
      )
      helpers.updateOptionsRefs(ctx.modalOptions, ctx.modalsByModalType)
      helpers.updateOptionsRefs(ctx.customerOptions, ctx.customersByCustomerVIP)

      const fieldsById = _.keyBy(meta.fields, (f) => f.id)

      fieldsById.purchaseContract.options = ctx.purchaseContractOptions
      fieldsById.centerTypeOrigin.options = ctx.centerTypeOriginOptions
      fieldsById.centerOrigin.options = ctx.centerOriginOptions
      fieldsById.centerTypeDestination.options =
        ctx.centerTypeDestinationOptions
      fieldsById.centerDestination.options = ctx.centerDestinationOptions
      fieldsById.modalType.options = ctx.modalTypeOptions
      fieldsById.modal.options = ctx.modalOptions
      fieldsById.customer.options = ctx.customerOptions
      fieldsById.incoterm.options = ctx.incotermOptions

      const getCenterOriginOptions = (value) =>
        value?.value == null
          ? ctx.centerOriginOptions
          : ctx.centersOriginByCenterTypeOrigin[value.value]
      fieldsById.centerTypeOrigin.onValueChange = (changedValue, fields) => {
        helpers.updateOptions(
          this,
          fieldsById.centerOrigin,
          fields.centerOrigin,
          getCenterOriginOptions(changedValue)
        )
      }

      const getCenterDestinationOptions = (value) =>
        value?.value == null
          ? ctx.centerDestinationOptions
          : ctx.centersDestinationByCenterTypeDestination[value.value]
      fieldsById.centerTypeDestination.onValueChange = (
        changedValue,
        fields
      ) => {
        helpers.updateOptions(
          this,
          fieldsById.centerDestination,
          fields.centerDestination,
          getCenterDestinationOptions(changedValue)
        )
      }

      const getModalOptions = (value) =>
        value?.value == null
          ? ctx.modalOptions
          : ctx.modalsByModalType[value.value]
      fieldsById.modalType.onValueChange = (changedValue, fields) => {
        helpers.updateOptions(
          this,
          fieldsById.modal,
          fields.modal,
          getModalOptions(changedValue)
        )
      }

      this.createValidationSchema = (meta, translate) => {
        const validationSchema = generateFieldsSchema(meta, translate)
        const localityFields = helpers.getLocalityFields()
        const localityOriginFields = {}
        localityFields.forEach(
          (field) => (localityOriginFields[field] = field + 'Origin')
        )
        helpers.createBaseLocalityValidations(
          validationSchema,
          translate,
          localityOriginFields
        )
        const localityDestinationFields = {}
        localityFields.forEach(
          (field) => (localityDestinationFields[field] = field + 'Destination')
        )
        helpers.createBaseLocalityValidations(
          validationSchema,
          translate,
          localityDestinationFields
        )

        return yup.object().shape(validationSchema)
      }

      return meta
    }

    getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
